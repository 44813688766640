@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 300;
  src: url("Ubuntu-Light.ttf"); /* IE9 Combat Modes*/
  src: local("Ubuntu"), url("Ubuntu-Light.ttf");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: url("Ubuntu-Regular.ttf"); /* IE9 Combat Modes*/
  src: local("Ubuntu"), url("Ubuntu-Regular.ttf");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  src: url("Ubuntu-Medium.ttf"); /* IE9 Combat Modes*/
  src: local("Ubuntu"), url("Ubuntu-Medium.ttf");
}

@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  src: url("Ubuntu-Bold.ttf"); /* IE9 Combat Modes*/
  src: local("Ubuntu"), url("Ubuntu-Bold.ttf");
}
